import React, { useEffect } from 'react';
import Script from 'next/script';
// import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
// import config from '../config/config.js';
import Footer from '../components/Footer.js';
import { TransitionProvider } from '../context/TransitionContext';
import TransitionLayout from '../animation/TransitionLayout';
// import { Red_Hat_Display } from 'next/font/google';
import getOrganizationSchema from '../api/schemaorg/getOrganizationSchema.js';
import { useOnRenderedContext } from '../context/OnRenderedContext.js';
import { useRouter } from 'next/dist/client/router';

// const redHatDisplay = Red_Hat_Display({
//   subsets: ['latin'],
//   weight: ['300', '400', '700'],
//   style: ['normal', 'italic'],
//   display: 'swap',
// });

export default function AppChildren({ Component, pageProps }) {
  const schemaOrganization = getOrganizationSchema();
  const [onRendered, setOnRendered] = useOnRenderedContext();
  const router = useRouter();

  useEffect(() => {
    if (router?.asPath) {
     //ELENCARE tutte le rotte che non hanno componenti con ssr
      switch (router?.asPath) {
        case '/cerca-mutuo':
        case '/cerca-mutuo#top':
        case '/preventivi-mutuo?show_chat=1':
        case '/preventivi-mutuo':
        case '/verifica-fattibilita/domande#top':
        case '/verifica-fattibilita/domande':
        case '/verifica-fattibilita':
        case '/cookie-policy':
        case '/privacy-policy':
        case '/termini-condizioni':
        case '/guide':
        case '/guide/mutui-e-tassi':
        case '/guide/dizionario-mutui':
        case '/guide/domande-frequenti':
        case '/': {
          setOnRendered(true);
          break;
        }

        default: {
          if (router?.asPath.startsWith('/lp/')) {
            setOnRendered(true);
          } else {
            setOnRendered(false);
          }
        }
      }  
    }
  }, [router?.asPath]);

  return (
    <TransitionProvider>
      <TransitionLayout>
        {/* <GoogleReCaptchaProvider useEnterprise reCaptchaKey={config.captchaKey} language="it"> */}
          <Script src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" />
          <Script type="text/javascript" src="/jslib/gtag.js" async />
          <Script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaOrganization) }} />
          <Component {...pageProps} />
        {/* </GoogleReCaptchaProvider> */}
        {onRendered && <Footer />}
      </TransitionLayout>
    </TransitionProvider>
  );
}
